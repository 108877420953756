.container {
  position: relative;
  width: 40% !important;
  padding: 40px;
  box-sizing: border-box;
  background-color: #ecf2ff;
}

.form-heading{
  margin-top: 12px;
  /* background: rgb(146 156 196); */
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.inputs {
  text-align: left;
  margin-top: 6px;
}

.name-fields-container{
  display: flex;
  flex-direction: horizontal;
  gap: 2%;
}

label, input, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.Problem-select{
  background: white;
  padding: 6px 6px;
  height: auto;
  width: 100%;
  font-size: 12px;
  border-radius: 10px;
  border-color: transparent;
  font-size: 15px;
  font-family: 'poppins', sans-serif;
}

label {
  margin-bottom: 4px;
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 12px;
}

input::placeholder {
  color: gray;
  font-family: 'Poppins', sans-serif;
}

input {
  background: #ffffff;
  padding: 16px 10px;
  height: 30px;
  font-size: 15px !important;
  border-radius: 10px;
}

.Submit-btn-bookAppointment {
  width: 100%;
  color: white;
  margin-top: 20px;
  padding: 6px;
  background: rgb(40, 59, 138);
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  letter-spacing: 0.7px;
  border-radius: 14px;
  cursor: pointer;
  font-weight: 900;
  transition: 0.5s;
}

button:hover {
  box-shadow: none;
}

.TimingsGrid {
  display: grid !important;
  grid-template-columns: repeat(3,30%);
  justify-content: space-around;
  row-gap: 6px;
  column-gap: 1%;
  margin: 0 0 10px 0;
}
.BookAppointment-radio-location-select{
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
}
.TimingsGrid > button {
  margin: 0;
  border: 1px solid #bdc3c7 !important;
  border-radius: 10px !important;
  height: 40px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: black !important;
}
.MuiFormControl-root {
  width: 100% !important;
}
.BookAppointment-radio-group-Method-Select{
  flex-wrap: nowrap !important;
  flex-direction: row !important;
  padding: 0 8px;
}
.BookAppointment-radio-group-Method-Select > label {
  margin-top: 0px;
}
.Consultation-Mode-Container {
  margin: 14px 0 0px 0;
  display: block;
  border:  1px solid black;
  border-radius: 20px;
  padding: 6px 6px 16px 6px;
}
.Consultation-Mode-Container > label {
  margin-top: 6px !important;
}

@media screen and (max-width: 900px) {
  .container {
    width: 60% !important;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
  
}