.doctor-section {
    padding: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 0 0 0;
    text-align: center;
    background-color: white;
}

.doc-title-content {
    margin: 0 0 12px 0;
}

.doc-title {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.7px;
}

.doc-description {
    text-align: left;
    color: rgb(77, 75, 75);
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 1.8rem;
}

/* Doctor Cards */
.doc-cards-content {
    box-sizing: content-box;
    width: auto;
    overflow: visible;
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding: 16px 0px;
}

.responsive-cards {
    width: fit-content;
    display: block;
    gap: 40px;
}

.doc-card-title-info {
    display: flex;
    flex-direction: column;
}

.doc-card {
    width: auto;
    height: auto;
    gap: 32px;
    display: flex;
    flex-direction: row;
    text-align: left;
    font-family: "Rubik", sans-serif;
}

.doc-card-img {
    width: 300px;
    height: 300px;
    background-position: center;
    background-color: #dde5f8;
    border-radius: 20px;
}

.doc-card-name {
    margin: 0 0 0 0;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.7px;
}

.doc-card-title {
    margin: 6px 0 0px;
    color: #494949;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    letter-spacing: 0.7px;
}

.doc-card-stars {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.7px;
    margin: 0px 0px 8px;
}

.doc-card-reviews {
    color: #5a5a5a;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.7px;
}

.description-text {
    border: 1px solid #cacaca;
    border-radius: 10px;
    height: 120px;
    overflow: scroll;
    padding: 6px;
    margin-bottom: 0;
}

.doctor-bookApp-button {
    border: 1px solid #283b8a;
    border-radius: 30px;
    width: auto;
    color: aliceblue;
    background-color: #283b8a;
    padding: 10px 20px;
}

.doc-rw-btns {
    display: flex;
    flex-direction: row;
}

.doc-rw-next-btn {
    background: none;
    text-align: right;
}

.doc-rw-back-btn {
    background: none;
    text-align: left;
}

/* Responsive */
@media screen and (max-width: 600px) {
    .doc-card {
        flex-direction: column;
        gap: 0;
        text-align: left;
    }

    .doc-cards-content {
        padding: 22px 0;
    }

    .doc-image-container {
        display: flex;
        flex-direction: row;
        margin: 0 0 12px 0;
    }

    .doctor-section {
        padding: 16px 12px;
    }

    .doc-card-title-info {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 12px;
    }

    .doc-title {
        font-size: 28px;
    }

    .doc-card-img {
        width: 150px;
        height: 150px;
        background-position: center;
        background-color: #dde5f8;
        border-radius: 20px;
    }

    .description-text {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 1.25rem;
    }
    .doc-card-name {
        font-size: 18px;
    }
    .doc-card-title {
        font-size: 16px;
    }
    .doc-card-stars {
        font-size: 16px;
    }
    .doc-card-reviews{
        font-size: 16px;
    }
    .doctor-bookApp-button{
        width: 100%;
    }
}