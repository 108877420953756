.ba-section {
    padding: 32px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background-color: white;
}
/* Image */
.ba-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}
.ba-image1 {
    width: 80%;
    height: auto;
}
/* Text */
.ba-text-content {
    width: auto;
}
.ba-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .7px;
    text-align: center;
    
}
.ba-title > span {
    position: relative;
}
.ba-title span::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #37B34A;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.ba-description {
    margin: 32px 0;
    color: #4d4b4b;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}
/* Checks */
.ba-checks {
    margin: 28px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
}
.ba-check-first {
    margin-top: 40px;
}
.ba-check-last {
    margin-bottom: 40px;
}
/* Book Appointment Button */
.ba-appointment-btn {
    padding: 18px 24px;
    color: white;
    border: 1px solid transparent;
    border-radius: 50px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}
.ba-appointment-btn:hover {
    color: #1A8EFD;
    background-color: transparent;
    border: 1px solid #1A8EFD;
}
/* Responsive */
@media screen and (max-width: 1000px) {
    .ba-section {
        display: block;
        text-align: left;
        padding-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }
    .ba-title {
        font-size: 20px;
      }
    .ba-image-content {
        visibility: hidden;
        width: 0;
        margin: 0 0 0 0;
    }
    .ba-text-content {
        width: 100%;
    }
    .ba-image1 {
        width: 0;
        height: 0;
    }
    .ba-description {
        text-align: left;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 16px;
        margin-bottom: 20px;
    }
    .ba-checks {
        margin: 9px 0;
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: .8px;
        text-align: left;
    }
    .ba-title > span {
        position: relative;
    }
    .ba-title span::before {
        height: 0;
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #37B34A;
        border-radius: 8px;
        left: 0;
        bottom: -4px;
    }
}
@media screen and (max-width: 1000px) {
    .ba-title{
        text-align: left;
    }
}