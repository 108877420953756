.treatments-section {
  padding: 24px 12px;
  text-align: center;
  background-color: white;
}
.treatments-title-content {
  margin: 80px 0 0 0;
}
.treatments-tabs > ant-tabs-tab {
  padding: 0;
}
.treatments-title {
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 26px !important;
  font-weight: bold;
}
.treatments-title > span {
  position: relative;
}
.treatments-title span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #37B34A;
  border-radius: 8px;
  left: 0;
  bottom: -4px;
}
.treatments-title-description {
  margin: 16px 0 24px 0;
  text-align: center;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 16px ;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
.treatments-description {
  margin: 64px;
  text-align: center;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
.ant-tabs-nav-list {
  align-items: center;
}
.treatment-specialist {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
}
.treatments-surgical{
  font-size: 26px !important;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.treatment-description {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 8px;
  text-align: left;
}

.treatment-case {
  font-size: 18px !important;
  font-weight: 500;
  margin: 10px 0;
}

.treatment-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 20px 0 10px;
}

.treatment-priceUD {
  font-family: "lora", serif;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
.treatment-priceUD > p {
  font-family: "Rubik", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
.treatment-priceMK-head {
  font-family: "lora", serif;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
}
.treatment-priceMK-head > p {
  font-family: "Rubik", sans-serif;
  text-decoration: line-through;
  margin-bottom: 0;
  padding-left: 4px;
}
.treatment-priceUD-head {
  font-family: "lora", serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  
}
.treatment-priceUD-head > p {
  font-family: "Rubik", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  padding-left: 4px;
}
.treatment-priceMK {
  font-family: "lora", serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.treatment-priceMK > p {
  font-family: "Rubik", sans-serif;
  text-decoration: line-through;
  padding-left: 4px;
  margin-bottom: 0;
}
.treatments-hr{
  margin-top: 2px;
}
.treatment-comparision-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.treatment-comparision-item {
  flex: 1;
}

.comparision-item {
  font-size: 1rem;
  line-height: 1.6;
}
.treatment-points {
  text-align: left;
  font-family: "Rubik", sans-serif;
  padding-left: 1rem;
  font-size: 20px;
  font-weight: bold;
}
.quadrant {
  background-color: #e6e6e6;
  padding: 10px;
}
.treatment-cases .quadrant {
  flex: 1;
  max-width: 50%;
  border-radius: 14px;
}

.treatment-cases {
  display: flex;
  flex-wrap: wrap;
  background-color: #e6e6e6;
  border-radius: 14px;
}
.Aligner-image > img {
  width: 40%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #cacaca;
}
.surgical-images {
  width: 40%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #cacaca;
}
.rct-images {
  width: 40%;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #cacaca;
}
.rct-main-image {
  width: 40%;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #cacaca;
}
@media screen and (max-width: 900px) {
  .Aligner-image > img {
    width: 50%
  }
}
@media screen and (max-width: 600px) {
  .treatments-title-description {
    margin: 16px 0 0px 0;
  }
  .Aligner-image > img {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 14px;
  }
  .surgical-images {
    width: 80%;   
    margin-top: 14px;
  }
  .rct-images {
    width: 90%;
  }
  .rct-main-image{
    width: 90%;
    margin-top: 14px;
    margin-bottom: 20px;
  }
  .treatment-specialist {
    font-size: 20px;
  }
  .treatment-priceMK-head {
    font-size: 14px;
  }
  .treatment-priceUD-head {
    font-size: 16px;
  }
  .treatments-hr{
    visibility: collapse;
    display: none;
  }
  .treatment-description {
    font-size: 1rem;
  }
  .treatment-points{
    font-size: 1rem;
  }
  .treatments-surgical{
    font-size: 21px !important;
    font-weight: 600;
    margin-bottom: 6px;
  }
}