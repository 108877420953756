/* FAQ Content Section */
.faq-full{
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
}
.faq-content {
  padding-top: 80px;
  box-sizing: border-box;
}
.footer-content{
  box-sizing: border-box;
}
.ant-tabs-nav-wrap {
    justify-content: space-evenly;
}
.ant-tabs-top {
    position: top;
}
.ant-tabs-nav-list {
  font-size: 32px;
  font-weight: bold;
  padding-left: 0px;
  padding-right: 0px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.8px;
}
.ant-tabs-tab {
  width: 50%;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.ant-tabs-tab-btn {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.ant-collapse-item {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.ant-collapse-header {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.8px;
}
.ant-collapse-content-box {
  text-align: center;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}
/* Responsive */
@media screen and (max-width: 500px) {
  .ant-tabs-nav-wrap {
    justify-content: left;
  }
}