.info-section {
  padding: 0 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 16px;
  margin-bottom: 0px;
  text-align: center;
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
}
.info-title-content {
  margin: 64px 0 0 0;
}
.info-title {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
}

.info-title > span {
  position: relative;
}
.info-title span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #39b54a;
  border-radius: 8px;
  left: 0;
  bottom: -6px;
}
.info-laptop-pop-up {
  box-sizing: border-box;
  border: 1px solid #cacaca;
  height: auto;
  padding: 16px;
  width: 30%;
  margin: 30px 0 11px 0;
  border-radius: 12px;
  background:#283b8b;
}
.info-laptop-pop-up > h3 {
  color: aliceblue;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-bottom: 18px;
}
.info-laptop-pop-up > p {
  color: aliceblue;
  font-family: 'poppins',sans-serif;
  font-size: 14px;
}
.info-Laptop {
  display: flex;
  flex-direction: row;
  gap: 2%;
  align-items: center;
  height: auto;
}
.info-description {
  margin: 48px 0 10px 0;
  text-align: center;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
/* Cards */
.info-cards-content {
  margin: 32px 0;
}
.info-cards-content > a {
  text-decoration: none;
  color: black;
}
.info-cards {
  height: 100%;
  width: 100% !important ;
  border: 2px solid #d7d3d3;
  border-radius: 12px;
  background-color: white;
  position: relative;
}
.treatments-knowMore {
  border: 2px solid #d7d3d3;
  border-radius: 10px;
  background-color: white;
  position: relative;
}
.treatments-knowMore > a {
  color:#283b8b;
  font-size: 15px;
  text-decoration: none;
}
.info-link-btn {
  background-color: transparent;
  margin-top: 0px;
  visibility: collapse;
}
.infoLinkPopup {
  padding: 24px;
  box-sizing: border-box;
  border-width: 2px;
  background-color: #283b8b;;
  display: none;
  text-align: center;
  color: #ecf2ff;
  border-radius: 10px;
  position: fixed;
  height: 210px;
  bottom: 180px;
  left: 7%;
  right: 7%;
  z-index: 9;
  opacity: 95%;
}
.infoLinkPopup > h3 {
  margin-bottom: 14px;
}
.infoLink-CloseBtn{
  color: #ecf2ff;
  background-color: transparent;
  text-decoration: underline;
}
.info-link{
  background-color: transparent;
  color: #283b8b;
  font: 14px sans-serif;
  letter-spacing: 0.6px;
  text-decoration: underline;
}
.info-card-title {
  margin: 28px 0 -10px 0;
  padding: 6px 2px 0px 2px;
  color: #283b8b !important;
  height: 30%;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-align: center;
  line-height: 1.3;
}
.info-card-starting {
  font-size: 18px;
  font-family: "lora", serif;
  margin-bottom: -6px;
  margin-top: 15px;
}
.info-card-marketPrice {
  position: relative;
  font-size: 18px;
  margin-bottom: 1px;
  font-family: "Rubik", sans-serif;
}
.info-card-marketPrice:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;
  
  -webkit-transform:rotate(-5deg);
  -moz-transform:rotate(-5deg);
  -ms-transform:rotate(-5deg);
  -o-transform:rotate(-5deg);
  transform:rotate(-5deg);
}
.info-card-our {
  font-size: 22px;
  font-family: "lora", serif;
  margin-bottom: -4px;
  font-weight: bold;
}
.info-card-ourPrice {
  font-size: 24px;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  padding-bottom: 12px;
}
.info-card-description {
  margin: 20px;

  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.6rem;
}
.info-card-icon {
  position: absolute;
  top: -24px;
  left: 40%;
  padding: 10px;
  background-color: #59c168;
  border-radius: 50%;
}
.info-fa-icon {
  color: #2c96ff;
  font-size: 24px;
}
.info-grid {
  display: grid;
  width: 100%;
  grid-gap: 3%;
  grid-template-areas: "rct aligners surgery";
  grid-template-columns: 31.33% 31.33% 31.33%;
  margin-bottom: -24px;
}
#rct {
  grid-area: rct;
}
#aligners {
  grid-area: aligners;
}

#surgery {
  grid-area: surgery;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .info-description {
    margin: 24px 0;
  }
  .info-laptop-pop-up {
    visibility: collapse;
    display: none;
    height: 0;
    width: 0;
  }.info-Laptop {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
  .info-title {
    font-size: 28px;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .slick-dots {
    padding: 10px;
    bottom: -30px;
    visibility: visible !important;
  }
  .info-grid {
    display: grid;
    width: 100%;
    grid-gap: 3%;
    grid-template-areas: "rct aligners surgery";
    grid-template-columns: 31.33% 31.33% 31.33%;
    margin-bottom: -24px;
  }
  .info-link-btn {
    background-color: transparent;
    margin-top: 0px;
    visibility: visible;
  }
}
@media screen and (max-width: 700px) {
  .info-card-starting {
    font-size: 12px;
  }
  .info-card-marketPrice {
    font-size: 13px;
  }
  .info-card-our {
    font-size: 14px;
  }
  .info-card-ourPrice {
    font-size: 16px;
    padding-bottom: 0px;
  }
  .info-description {
    margin: 24px 0 6px 0;
  }
  .slick-dots {
    bottom: 20px;
    visibility: visible !important;
  }
  .info-title span::before{
    margin-top: -6px;
  }
  .info-card-title{
    font-size: 16px;
  }
  .info-card-icon {
    left: 27%;
  }
}
.slick-track div {
  margin: 0px;
  margin-top: 8px !important;
  margin-bottom: 0 !important;
  width: 100%;
}
.slick-slide {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 10px 0;
}
.slick-dots li button:before {
  font-size: large;
}

.slick-dots {
  padding: 10px;
  visibility: hidden;
}
