.Login-Container {
    margin-top: 74px;
}
.Login-Title {
    text-align: center;
    font-size: 24px;
    color: #444;
    margin-bottom: 16px;
}
.Login-Card {
    margin: 100px 10px 0px 10px;
    /* margin: 4px; */
    border: 1px solid;
    border-color: lightgrey;
    border-radius: 10px;
}
.Login-Form {
    align-items: center;
    justify-content: center;
    font-family: 'Rubik', sans-serif;
    font-size: 12px !important;
}
.Btn-Container{
    display: flex;
    justify-content: center;
}
.Login-Button {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgb(40, 59, 138);
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 20px;
    margin-top: 16px;
    padding: 0px;
}
.Login-Button:hover {
    background: #2f2f2f;
}
.Login-Button:active {
    background: #004477;
}
.Forgot-Password {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    color: #444;
    font-size: 12px;
    margin-top: 12px;
}
.Forgot-Password:hover {
    color: #0070c9;
}
.New-User {
    width: 100%;
    text-align: center;
    margin-top: 6px;
    color: #444;
    font-size: 18px;
}
.otp-container > input {
    border: 1px solid #cacaca;
    border-radius: 5px;
}
@media screen and (max-width: 380px) {
    .otp-container > input {
        width: 30px !important;
    }
}