.section-container {
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
}
.hero-section {
  padding: 74px 24px 12px 0px;
  display: flex;
  justify-content: center;
  gap: 24px;
  background: #283b8b;
}
.hero-text-image-container {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.hero-image-container-inner-text {
  padding: 24px;
}
.location-banner {
  font-size: 16px;
  text-align: center;
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 4px;
}
.register-popup {
  display: none;
  position: fixed;
  bottom: 90px;
  left: 10%;
  right: 10%;
  z-index: 9;
}

/* 30% Text section */
.hero-text-section-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 10px solid #38b24a;
}
.hero-text-section-heading-container {
  width: 50%;
  border-left: solid 2px#38B24A;
  padding: 10px 10px 4px 10px;
}
.hero-text-section-banner1 {
  width: 50%;
  padding: 10px 10px 4px 10px;
}
.hero-text-section-banner1 > h3 {
  padding-top: 8px;
  padding-bottom: 1px;
  font-size: 52px;
  font-family: "Anton", sans-serif;
  color: #ffffff;
  margin-bottom: 0;
}
.hero-text-section-banner1 > h4 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;

}
.hero-text-section-banner1 > a {
  font-size: 14px;
  color: #a9e9ff !important;
  text-decoration: underline !important;
  font-family: 'Rubik', sans-serif;

}
.hero-text-section {
  position: relative;
  background: #283b8b;
  height: 330px;
  border-right: 2px solid #38B24A;
}
.hero-text-section-heading {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}
.hero-text-section-heading > p {
  font-size: 14px;
  color: #ffffff;
  text-align: end;
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;

}
.hero-text-section-heading > h3 {
  text-align: end;
  font-family: "Anton", sans-serif;
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 0;
}
.hero-text-section-sub {
  display: flex;
  flex-direction: column;
  text-align: end;
  align-items: flex-end;
}
.hero-text-section-sub > h4 {
  text-align: end;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;

}

.hero-text-section-sub > a {
  font-size: 14px;
  padding-top: 2px;
  color: #a9e9ff !important;
  text-decoration: underline !important;
  font-family: 'Rubik', sans-serif;

}
.hero-text-section-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hero-text-section-content-img {
  width: 50%;
  width: 200px;
}
.hero-text-section-content-p {
  width: 50%;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 4px 0 0;
}
.hero-text-section-content-p > h3 {
  font-size: 22px;
  text-align: right;
  font-weight: 400;
  padding-right: 6px ;
  font-family: 'Rubik', sans-serif;
}
.hero-bookApp-section {
  margin-top: 5px;
  width: 500px;
  /* border: 1px solid #cacaca; */
  /* border-radius: 20px; */
  /* padding: 14px; */
}
.hero-image-bg {
  width: 200px;
}
.hero-button1 {
  background: none;
  color: rgb(151, 225, 255);
  text-align: left ;
  font-size: 14px;
  text-decoration: underline;
}
.hero-button2 {
  background: none;
  color: rgb(151, 225, 255);
  text-align: right;
  font-size: 14px;
  text-decoration: underline;
}
/* Green Button Pop-Up */
.greenButtonPopup {
  padding: 10px;
  box-sizing: border-box;
  border-width: 2px;
  background-color: #283b8b;
  border: solid 2px white;
  display: none;
  text-align: center;
  border-radius: 10px;
  position: fixed;
  height: 260px;
  top: 215px;
  left: 1%;
  right: 1%;
  z-index: 9;
  color: #ffffff;
  opacity: 97%;
}
.greenButtonPopup > h3 {
  margin-bottom: 14px;
}
.greenButtonPopup > ul {
  text-align: left;
}
.greenButtonPopup-CloseBtn{
  background-color: transparent;
  color: white;
  text-decoration: underline;
}
.pop-up-img{
  width: 100%;
  margin: 0;
}
/* Get OTP button */

.Submit-btn {
  width: 80px;
  margin-top: 0 !important;
}
.form-heading {
  background: none !important;
}
.heroDashboard {
  background: white;
  border: 1px solid #cacaca;
  border-radius: 20px;
  height: 250px;
  padding: 48px 16px 0 6px;
  text-align: center;
}
.heroDashboard > a {
  text-decoration: none;
  color: rgb(40, 59, 138) !important;
}
.heroDashboard > a > h2 {
  font-size: 22px !important;
  font-weight: bold;
  font-family: "poppins", sans-serif;
}
.heroDashboard > p {
  font-size: 16px;
  font-weight: bold;
  font-family: "poppins", sans-serif;
  color: black !important;
}

/* Text section */

#popup-button {
  visibility: hidden;
}
/* Scroll Btn */
.scroll-up {
  bottom: 20px;
  right: 20px;
  height: 50px;
  font-size: 22px;
  align-items: center;
  appearance: none;
  background-color: rgb(40, 59, 138);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd, sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: fixed;
  float: right;
  z-index: 9;
  text-align: center;
  text-decoration: none;
  transition: transform 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  overflow: hidden;
}
.scroll-up:hover,
.scroll-up:focus {
  animation: bubble 0.4s ease-out;
}
@keyframes bubble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.text-box-icon {
  height: 24px;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .hero-section {
    padding: 74px 0px 4px 0px;
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }
  #popup-button {
    visibility: visible;
  }
  .hero-bookApp-section {
    visibility: collapse;
    width: 0;
    height: 0;
  }
  .hero-image-container-inner-text {
    padding: 16px;
  }
  .hero-text-section {
    height: 370px;
  }
  .hero-image-text > h3 {
    font-size: 22px !important;
    font-weight: bold;
  }
  .hero-image-text-900 {
    visibility: hidden;
    height: 0;
  }
  .hero-image-text-a-900 {
    visibility: hidden;
    height: 0;
  }
  .hero-image-section {
    width: 100%;
  }
  .hero-image-text {
    padding-left: 10px;
    padding-top: 16px;
    visibility: visible;
  }
  .hero-image-text-a {
    visibility: visible;
  }
  .text-section {
    width: 0;
    height: 0;
    visibility: hidden;
    padding: 0;
    margin-top: 22px;
  }
  .text-title,
  .text-descritpion {
    width: 100%;
  }
  .hero-text-section-content-p > h3 {
    font-size: 24px;
  }
  .hero-text-section-heading > h3 {
    font-size: 100px;
  }
  .hero-text-section-sub > h4 {
    font-size: 24px;
  }
  .hero-text-section-banner1 > h3 {
    font-size: 90px;
    padding-top: 12px;
  }
  .hero-text-section-banner1 > h4 {
    font-size: 24px;
  }
  .hero-text-section-content-img{
    padding: 12px;
  }
  .text-section-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 4px;
  }
  .text-appointment-btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 700px) {
  .hero-image-container-inner-text {
    padding: 0px;
  }
  #popup-button {
    visibility: visible;
  }
  .text-box-item {
    width: 100%;
    padding-top: 6px;
    padding-bottom: 12px;
  }
  .hero-image-bg {
    width: 100%;
    padding: 10px;
  }
  .hero-image-text-900 {
    visibility: hidden;
    height: 0;
  }
  .hero-image-text-a-900 {
    visibility: hidden;
    height: 0;
    text-align: left;
  }
  .hero-image-text {
    padding-left: 24px;
    padding-top: 16px;
    visibility: visible;
  }
  .hero-image-text-a {
    visibility: visible;
    padding-left: 24px;
    padding-bottom: 10px;
    font-size: 16px;
  }
  .hero-image-text > h3 {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
  .hero-image-text > h4 {
    font-style: italic;
    font-size: 16px;
  }
  .hero-image-container > p {
    font-family: serif;
    font-size: 12px;
    vertical-align: bottom;
    text-align: right;
  }
  .text-box-content > h3 {
    font-size: 18px;
    color: rgb(77, 75, 75);
  }
  .text-box-content > p {
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    margin-bottom: 12px;
  }
  .hero-section {
    flex-direction: column;
    gap: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 75px;
  }
  .hero-image-section {
    height: 272px;
    background: #283b8b;
  }
  .text-section {
    width: 100%;
    padding: 0px 8px;
    margin-top: 0px;
    text-align: center;
  }
  .text-headline {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .text-title {
    font-size: 28px;
  }
  .text-descritpion {
    font-size: 16px;
  }
  .text-appointment-btn {
    font-size: 24px;
    padding: 6px 18px;
  }
  .text-stats {
    gap: 18px;
  }
  .text-stats-container > p {
    font-size: 16px;
  }
  .text-stats-container p:first-child {
    font-size: 22px;
  }
  .scroll-up {
    bottom: 32px;
    right: 10px;
    position: fixed;
    height: 50px;
    font-size: 18px;
  }
  .hero-text-section-content-p > h3 {
    font-size: 16px;
    text-align: left;
    padding: 10px 0px 0 1px;
  }
  .hero-text-section-heading > h3 {
    font-size: 60px;
  }
  .hero-text-section-banner1 > h3 {
    font-size: 52px;
    padding-top: 8px;
  }
  .hero-text-section-banner1 > h4 {
    font-size: 20px;
  }
  .hero-text-section-sub > h4 {
    font-size: 20px;
  }
  .hero-text-section {
    height: 330px;
  }
  .hero-text-section-heading {
    margin-bottom: 2px;
  }
  .hero-text-section-banner1 > h3 {
    margin-bottom:3px;
  }
  .hero-text-section {
    border-right: none;
  }
  .timedpopup-inner {
    padding: 10px;
    box-sizing: border-box;
    border-width: 2px;
    background-color: #283b8b;
    border: solid 2px white;
    text-align: center;
    border-radius: 10px;
    position: fixed;
    height: 500px;
    top: 200px;
    left: 1%;
    right: 1%;
    z-index: 10;
    color: #ffffff;
    opacity: 97%;
  }
}
