.Container {
  padding: 80px 14px 0 14px;
}
/* .h1,
h1 {
  font-family: "Rubik", sans-serif;
  font-size: 24px !important;
}
.h2,
h2 {
  font-family: "Poppins", sans-serif;
  font-size: 22px !important;
  font-weight: bold;
}
.h3,
h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-weight: bold;
}
.h4,
h4 {
  font-family: "lora", sans-serif;
  font-size: 16px !important;
} */
.Client-info-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px;
  border-bottom-style: groove;
  padding-bottom: 5px;
  align-items: center;
}
.Client-info-title > h1 {
  font-family: "Rubik", sans-serif;
  font-size: 20px !important;
  font-weight: 400;
  margin-bottom: 0;
}
.Client-info-title > button {
  border: none;
  height: 36px;
  background-color: #283b8b;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: aliceblue;
  cursor: pointer;
  width: 10%;
  visibility: hidden;
}
.Client-info-content {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.Client-info-status {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  margin-bottom: 8px;
}

.Client-info-status > h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-weight: bold;
}
.Client-info-status > h4 {
  font-family: "lora", sans-serif;
  font-size: 16px !important;
  box-sizing: border-box;
  padding: 6px;
  background-color: #9de6a7;
  border-radius: 10px;
}
.Client-info-remarks {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: top;
  margin-bottom: 8px;
  text-align: left;
}
.Client-info-remarks > h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-weight: bold;
}
.Client-info-remarks > h4 {
  font-family: "lora", sans-serif;
  font-size: 16px !important;
  font-weight: 400;
  text-align: left;
}
.Client-info-problem {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
}
.Upcoming-Appointment-info {
  box-sizing: border-box;
  padding: 12px 14px 16px 14px;
  border-radius: 12px;
  background-color: #9de6a7;
}
.Upcoming-Appointment-info > h2 {
  font-family: "Poppins", sans-serif;
  font-size: 22px !important;
  font-weight: bold;
  margin-bottom: 12px;
}
.Client-Appointment-info {
  display: flex;
  flex-direction: column;
}
.Appointment-info-Date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Appointment-info-Date > p {
  margin-bottom: 4px;
}
.Appointment-info-Doctor > p {
  margin-bottom: 4px;
}
.Appointment-info-Location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Appointment-info-Location > p {
  margin-bottom: 0;
}
.Appointment-info-Location > button {
  border-radius: 10px;
  background-color: #283b8b;
  color: aliceblue;
  width: 120px;
  height: 32px;
}
.Previous-Appointment-info {
  box-sizing: border-box;
  padding: 8px 14px;
  border-radius: 12px;
  background-color: #cacaca;
  margin-bottom: 14px;
}
.Previous-Appointment-Conainer > h2 {
  font-family: "Poppins", sans-serif;
  font-size: 22px !important;
  font-weight: bold;
}
.Previous-Appointment-info > p {
  margin-bottom: 4px;
}
.Dashboard-BookApp-button {
  width: 20%;
  background-color: #283b8b;
  border-radius: 18px;
  color: aliceblue;
  padding: 10px;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  margin-top: 10px;
}
@media screen and (max-width: 900px) {
  .Client-info-title > button {
    width: 25%;
    visibility: visible;
  }
  .Dashboard-BookApp-button {
    width: 40%;
  }
  .Appointment-info-Location > button {
    width: 120px;
  }
}
@media screen and (max-width: 600px) {
  .Client-info-title > button {
    width: 25%;
  }
  .Dashboard-BookApp-button {
    width: 40%;
  }
  .Appointment-info-Location > button {
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .Dashboard-BookApp-button {
    width: 100%;
  }
  .Client-info-status {
    justify-content: space-between;
    gap: 0;
  }
}
