.doctor-section {
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 0 0 0;
  text-align: center;
  background-color: white;
}
.dt-title-content {
  margin: 0 0 12px 0;
}
.dt-title {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
/* .dt-title > span {
  position: relative;
}
.dt-title span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #9d2553;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
} */
.dt-description {
  margin: 48px auto 0;
  text-align: center;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
/* Doctor Cards */
.dt-cards-content {
  box-sizing: content-box;
  width: auto;
  overflow: visible;
  display: flex;
  gap: 32px;
}
.responsive-cards {
  width: fit-content;
  display: block;
  gap: 40px;
}
.dt-card {
  width: 332px;
  height: 400px;
  gap: 32px;
  text-align: left;
  font-family: "Rubik", sans-serif;
}
.dt-card-img {
  width: 100%;
  max-width: 300px;
  height: 300px;
  background-position: center;
  background-color: #dde5f8;
  border-radius: 20px;
}
.dt-card-name {
  margin: 12px 0 0 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.dt-card-title {
  margin: 6px 0 8px;
  color: #494949;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0.7px;
}
.dt-card-stars {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.dt-card-reviews {
  color: #5a5a5a;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.slick-dots li button:before {
  font-size: large;
}
.slick-dots {
  padding-bottom: 10px;
}
.slick-next {
  right: 0px;
 }
 .slick-prev:before, .slick-next:before{
  font-size: 40px;
  color: #bbbbbb;
 }

.Link-dt-cards {
  text-decoration: none;
  color: #212529;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .doctor-section {
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 0 0 0;
    text-align: center;
    background-color: white;
  }
 .slick-next {
  right: 0px;
 }
  .dt-title {
    font-size: 20px;
    text-align: left;
  }
  .dt-title > span {
      position: relative;
  }
  .dt-title span::before {
      height: 0;
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #37B34A;
      border-radius: 8px;
      left: 0;
      bottom: -4px;
  }
  .dt-description {
    margin: 32px 0 0;
    font-size: 18px;
    text-align: left;
  }
  .dt-title-content {
    margin: 0 0 -12px 0;
  }
  .dt-card {
    width: 332px;
    height: 230px;
    gap: 10px;
    text-align: left;
    font-family: "Rubik", sans-serif;
  }
  .dt-card-img {
    width: 100%;
    max-width: 150px;
    height: 150px;
    background-position: center;
    background-color: #dde5f8;
    border-radius: 20px;
  }
  .dt-card-name {
    margin: 12px 0 0 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  .dt-card-title {
    margin: 0px 0 4px;
    color: #494949;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    letter-spacing: 0.7px;
  }
  .dt-card-stars {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  .dt-card-reviews {
    color: #5a5a5a;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
}
@media screen and (max-width: 600px) {
  .slick-prev:before, .slick-next:before{
    display: none;
   }
}
.slick-track div {
  margin: 0;
  margin-bottom: 0 !important;
  width: 100%;
}
.slick-slide {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 10px;
}
.slick-dots li button:before {
  font-size: large;
}
