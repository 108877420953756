#recaptcha-container{
    background-color: white;

}
.login-success{
    text-align: center;
    color: white;
    font-size: 22px;
}
.popup-text{
    font-size: 18px;
    font-family: 'rubik',sans-serif;
    text-align: center;
    width: fit-content;
    padding-bottom: 22px;
    color: white;
    font-weight: bold;

}
.popup-section{
    background-color: rgb(40, 59, 138);
    padding: 0 8px;
    border: 2px;
    border-radius: 25px;
    display: flex;
    height: 250px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.enter-otp-text{
    font-weight: bold;
    font-family: 'Rubik',sans-serif;
    font-size: 22px;
    margin-bottom: 16px;
    color: white;
    text-align: center;
}
.send-button {
    background-color: white;
    width: 100%;
    height: 40px;
    left: 15%;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px;
    border-radius: 8px;
    font-size: 16px;
}
.send-button-text {
    font-size: 16px;
    font-family: 'Rubik',sans-serif;
    font-weight: bold;
}
.phone-input-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.react-tel-input {
    width: 100%;
}
.phone-input-field{
    width: 100%;
}
.call-section {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    text-align: center;
    gap: 10px;
}
.call-section > p {
    color: aliceblue;
    margin-bottom: 4px;
}
.call-section > button {
    background-color: white;
    width: 100%;
    height: 40px;
    left: 15%;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}
.call-section > button > a {
    text-decoration: none;
    color: black;
}
.call-button-whatsapp {
    color: #25d366 !important;
    background-color: white;
    width: 100%;
    height: 40px;
    left: 15%;
    align-items: center;
    justify-content: center;
    border: 1px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}
.react-tel-input .form-control {
    width: 90%;
    padding-left: 40px;
    font-size: 18px;
    height: 40px;
}
.phone-input-OTP-button{
    width: 30%;
}
.otp-container > input {
    padding: 0;
    font-size: 22px;
    height: 40px !important;
    width: 40px !important;
    margin: 0 8px 20px 8px !important;
    border-radius: 0;
}
.otp-verify-btn {
    background: aliceblue;
    width: auto;
    padding: 10px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: black;
    border-radius: 10px;
}
.resend-otp-btn {
    margin-top: 12px;
    width: auto;
    border-radius: 10px;
    color: aliceblue;
    text-decoration: underline;
    background: transparent;
}
@media screen and (max-width: 400px) {
    .send-button-text {
        font-size: 12px !important;
        font-family: 'Rubik',sans-serif;
        font-weight: bold;
    }
    .call-button-whatsapp {
        font-size: 18px !important;
    }
    .call-section > button {
        font-size: 18px ;
    }
}